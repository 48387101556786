.review-line {
  display: flex;
  align-items: center;
  flex: 1;
  margin-bottom: 2em;
}

.review-line:last-child {
  margin-bottom: 0;
}

.review-indicator {
  width: 50%;
  margin: 1em;
  position: relative;
}

.review-percent {
  width: 100%;
  text-align: center;
  background: linear-gradient(to right,#ea9b39, #45a045);
  border-radius: 2em;
  height: 0.5em;
}

.review-point {
  width: 0.1em;
  height: 0.5em;
  background-color: #464646;
  bottom: 0.5em;
  position: absolute;
}

.review-point:after {
  content: "";
  width: 0.5em;
  height: 0.5em;
  background-color: #464646;
  bottom: 0.5em;
  position: absolute;
}
