.header {
  position: fixed;
  top: 0;
  left: 0;
  font-size: 1.8em;
  margin: 0;
  padding: 0;
  height: 3em;
  width: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 200ms ease-in-out;
  transform: translateY(-3.5em);
  box-shadow: 0px -10px 10px 4px black;
}

.header-opened {
  transform: translateY(0);
}
