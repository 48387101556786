.tracker {
  position: absolute;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  border-radius: 2px;
  width: 40px;
  height: 40px;
  bottom: 80px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.tracker-background {
  position: absolute;
  width: 30px;
}

.tracker-yes {
  width: 20px;
}
