.add-peep-background {
  background-color: #000000c2;
  position: absolute;
  width: 100vw;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(0);
}

.add-peep-card {
  background-color: white;
  padding: 2em;
  border-radius: 0.5em;
  box-shadow: 0px 10px 10px -10px black;
  transform: translateY(80vh);
  transition: transform 200ms ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.add-peep-card select {
  padding: 1em;
}

/* TODO: make a dedicated react component? */
.add-peep-card button {
  margin-top: 1em;
  padding: 1em 2em;
}

.add-peep-show {
  transform: scale(1);
}

.add-peep-show .add-peep-card {
  transform: translateY(0);
}
