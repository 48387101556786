.panel {
  position: absolute;
  bottom: 0;
  height: 30em;
  background-color: white;
  width: calc(100% - 4em);
  padding: 2em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 10px 10px 4px black;
  transform: translateY(35em);
  transition: transform 200ms ease-in-out;
  border-top-left-radius: 1em;
  border-top-right-radius: 1em;
}

.panel-infos {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.panel-name {
  font-size: 1.5em;
  padding: 0;
  margin: 0;
  height: 1.1em;
}

.panel-price {
  margin-top: 0.5em;
}

.panel-close {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2em;
  height: 2em;
  position: absolute;
  right: .5em;
  top: .5em;
  background: none;
  border: none;
  color: #8f8f8f;
  outline: none;
}

.panel-reviews {
  margin-top: 1em;
}

.panel-opened {
  transform: translateY(23em);
}

.panel-big-opened {
  transform: translateY(0);
}

.panel-report {
  height: 3em;
  display: flex;
  justify-content: center;
  padding-top: 1em;
}

.panel-collapse {
  border: none;
  background: none;
  position: absolute;
  top: 0;
  background-color: white;
  width: 3em;
  height: 1.5em;
  align-self: center;
  border-radius: 1em;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding-top: 0.2em;
  outline: none;
}

.panel-review-does-not-exist {
  font-size: 0.7em;
  color: white;
  border: 1px solid #d8d8d8;
  background-color: #c60101;
}
