.errors {
  position: absolute;
  bottom: 0;
  z-index: 100;
  list-style: none;
  padding: 0;
  margin: 0;
}

.error {
  margin: 0;
  padding: 0;
  background-color: white;
  color: #dd1a1a;
  border-radius: 0.5em;
  box-shadow: 0px 0px 10px -6px black;
  height: 1em;
  display: flex;
  align-items: center;
  justify-content: center;

  transform: scaleY(0);
  transform-origin: bottom;
  transition: all 200ms ease-in-out;
  margin-bottom: -1em;
  width: 10em;
  max-width: 20vw;
}

.error-print {
  transform: scaleY(1);
  margin: 1em;
  padding: 1.5em;
}
