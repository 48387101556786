.review {
  flex: 1;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
}

.review-text {
  font-size: 1.5em;
}

.review-count {
  font-size: 0.9em;
  margin-top: 0.5em;
}
