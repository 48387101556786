.app {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  flex: 1;
  background: #fff;
}

.google-map {
  /* from: https://css-tricks.com/the-trick-to-viewport-units-on-mobile/ */
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
}
